import { AnimatePresence, motion } from 'framer-motion';
import { Dialog } from '@headlessui/react';
import { HiShare } from 'react-icons/hi';
import { alertService } from 'utils/alerts';
import Button from 'components/button/Button';
import Video from 'components/blocks/Video';

interface VideoModalProps {
  url: string;
  name: string;
  open: boolean;
  handleClose: () => void;
  shareLink?: string;
}

const VideoModal = (props: VideoModalProps) => {
  const { url, name, open, shareLink, handleClose } = props;

  const copyLink = (e: any) => {
    e.preventDefault();
    if (!shareLink) return;
    navigator.clipboard.writeText(shareLink);
    const alertOptions = {
      id: 'global-alerts',
      autoClose: true,
      keepAfterRouteChange: false
    };
    alertService.success(`Link copied to your clipboard`, alertOptions);
    return false;
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          static
          as={motion.div}
          open={open}
          onClose={handleClose}
          className="relative z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          id="video-modal"
        >
          <div className="fixed inset-0 bg-black/70" aria-hidden="true" />
          <div className="fixed inset-0 flex items-center justify-center p-4">
            <Dialog.Panel className="mx-auto border border-black rounded-2xl bg-black w-[45%] h-fit relative pt-6">
              <div className="absolute -top-[30px] text-white pl-4 font-normal">
                {name}
              </div>
              {shareLink && (
                <a
                  href="#"
                  onClick={copyLink}
                  className="block absolute top-2 right-2 text-trhp-white text-xm w-7 h-7"
                >
                  <HiShare className="block" />
                </a>
              )}
              <Video url={url} name={name} />
              <div className="text-right">
                <Button variant="primary" className="m-4" onClick={handleClose}>
                  Close
                </Button>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export default VideoModal;
